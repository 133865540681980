<template>
    <v-card class="ma-4">
        <v-card-title>
            {{ title }}

            <v-divider
                    class="mx-6"
                    inset
                    vertical
            ></v-divider>


            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>

            <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    clearable
                    filled
                    hide-details
                    label="Search about something"
                    single-line
            ></v-text-field>

            <v-dialog v-model="dialogAddItem" max-width="750px">

                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                            class="mx-11"
                            color="red"
                            dark
                            v-bind="attrs"
                            v-on="on"
                    >New
                    </v-btn>
                </template>

                <v-card v-if="dialogAddItem">
                    <v-toolbar
                            color="blue-grey"
                            dark
                    >
                        <v-btn
                                dark
                                icon
                                @click="dialogAddItem = false"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn
                                    :disabled="(!valid || buttonLoading)"
                                    :loading="buttonLoading"
                                    dark
                                    text
                                    @click="validate"
                            >Save
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>


                    <v-alert
                            v-if="Object.keys(errors).length>0"
                            dense
                            text
                            type="error"
                    >
                        <h4 class="subtitle">Correct the following errors:</h4>
                        <ul>
                            <li
                                    v-for="(error, index) in errors"
                                    :key="index"
                            >{{ error.message || error }}
                            </li>
                        </ul>
                    </v-alert>


                    <v-card-text>
                        <v-container>

                            <v-form
                                    ref="form"
                                    v-model="valid"
                                    :disabled="buttonLoading"
                                    lazy-validation
                            >
                                <v-row>

                                    <v-col cols="12">
                                        <v-select
                                                v-model="user"
                                                :items="users"
                                                :rules="[v => !!v || 'Please select customer']"
                                                hide-details
                                                item-text="name"
                                                item-value="id"
                                                label="Customer Name"
                                                outlined
                                                required
                                                return-object
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-select
                                                v-model="category"
                                                :items="categories"
                                                :rules="[v => !!v || 'Please select category']"
                                                hide-details
                                                item-text="name"
                                                item-value="id"
                                                label="Category Name"
                                                outlined
                                                required
                                                return-object
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-select
                                                v-model="type"
                                                :items="types"
                                                :rules="[v => !!v || 'Please select type']"
                                                hide-details
                                                item-text="name"
                                                item-value="id"
                                                label="Type Name"
                                                outlined
                                                required
                                                return-object
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-select
                                                v-model="priority"
                                                :items="priorities"
                                                :rules="[v => !!v || 'Please select priority']"
                                                hide-details
                                                item-text="name"
                                                item-value="id"
                                                label="Priority level"
                                                outlined
                                                required
                                                return-object
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-model="price" :name="price" label="Price" min="0"
                                                      outlined type="number"
                                        ></v-text-field>

                                    </v-col>
                                    <v-col cols="6">
                                        <v-dialog
                                                ref="dialog"
                                                v-model="dialogCalendar"
                                                :return-value.sync="bookingDate"
                                                :show-current="false"
                                                persistent

                                                width="290px"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                        v-model="bookingDate"
                                                        label="Booking date"
                                                        outlined

                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                    v-model="bookingDate"
                                                    scrollable
                                            >
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                        color="red"
                                                        text
                                                        @click="dialogCalendar = false"
                                                >
                                                    Cancel
                                                </v-btn>
                                                <v-btn
                                                        color="red"
                                                        text
                                                        @click="$refs.dialog.save(bookingDate)"
                                                >
                                                    OK
                                                </v-btn>
                                            </v-date-picker>
                                        </v-dialog>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-dialog
                                                ref="startDialog"
                                                v-model="startTimeDialog"
                                                :return-value.sync="startTime"
                                                :show-current="true"
                                                persistent
                                                width="290px"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                        v-model="startTime"
                                                        label="Start time"
                                                        outlined
                                                        v-bind="attrs"
                                                        v-on="on"

                                                ></v-text-field>

                                            </template>
                                            <v-time-picker
                                                    v-if="startTimeDialog"
                                                    v-model="startTime"
                                                    :max="endTime"
                                                    color="primary"
                                                    format="24hr"
                                                    header-color="blue-grey"
                                                    scrollable
                                                    show-current
                                            >
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                        color="red"
                                                        text
                                                        @click="startTimeDialog = false"
                                                >
                                                    Cancel
                                                </v-btn>
                                                <v-btn
                                                        color="red"
                                                        text
                                                        @click="$refs.startDialog.save(startTime)"
                                                >
                                                    Ok
                                                </v-btn>
                                            </v-time-picker>
                                        </v-dialog>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-dialog
                                                ref="endDialog"
                                                v-model="endTimeDialog"
                                                :return-value.sync="endTime"
                                                :show-current="true"
                                                persistent
                                                width="290px"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                        v-model="endTime"
                                                        label="End time"
                                                        outlined
                                                        v-bind="attrs"
                                                        v-on="on"
                                                ></v-text-field>

                                            </template>
                                            <v-time-picker
                                                    v-if="endTimeDialog"
                                                    v-model="endTime"
                                                    :min="startTime"
                                                    color="primary"
                                                    format="24hr"
                                                    header-color="blue-grey"
                                                    scrollable
                                                    show-current
                                            >
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                        color="red"
                                                        text
                                                        @click="endTimeDialog = false"
                                                >
                                                    Cancel
                                                </v-btn>
                                                <v-btn
                                                        color="red"
                                                        text
                                                        @click="$refs.endDialog.save(endTime)"
                                                >
                                                    Ok
                                                </v-btn>
                                            </v-time-picker>
                                        </v-dialog>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-textarea v-model="note" :counter="1000" :rules="noteRules " auto-grow clearable filled
                                                    label="Notes"
                                        ></v-textarea>
                                    </v-col>
                                    <v-col cols="12" md="6" sm="6">
                                        <v-checkbox
                                                v-model="isActive"
                                                color="red"
                                                label="show service in the list"
                                        ></v-checkbox>
                                    </v-col>

                                </v-row>
                            </v-form>
                        </v-container>
                    </v-card-text>

                </v-card>
            </v-dialog>

        </v-card-title>


        <v-data-table
                :footer-props="{'items-per-page-options': [10, 20, 40, 80, 100]}"
                :headers="headers"
                :items="desserts"
                :items-per-page="10"
                :loading="loading"
                :options.sync="options"
                :search="search"
                class="elevation-1"
        >


            <template v-slot:item.actions="{ item }">
                <v-icon
                        class="mr-3"
                        small
                        @click="editItem(item)"
                >
                    mdi-pencil
                </v-icon>
                <v-icon
                        small
                        @click="deleteItem(item)"
                >
                    mdi-delete
                </v-icon>
            </template>


            <template v-slot:item.isActive="{ item }">
                <v-icon :color="(item.isActive)?'green':'red'">
                    mdi-eye
                </v-icon>
            </template>

            <template v-slot:item.userID="{ item }">
                {{ getCustomerName(item) }}
            </template>


            <template v-slot:item.typeID="{ item }">
                <v-chip color="primary" dark>{{ getJobCategoryName(item.typeID) }}</v-chip>
            </template>


            <template v-slot:item.categoryID="{ item }">
                <v-chip color="secondary" dark>{{ getJobCategoryName(item.categoryID) }}</v-chip>
            </template>


        </v-data-table>
    </v-card>
</template>

<script>
import networks from '../../services/networks';
import {urlCreateService, urlJobCategories, urlServices, urlUpdateService, urlUsersBasedRole} from "@/config";

export default {
    data: () => ({
        valid: true,
        search: '',
        id: 0,
        user: null,
        users: [],
        type: null,
        types: [],
        category: null,
        categories: [],
        jobCategories: [],
        priority: null,
        priorities: [{id: 1, name: "24 hour response"}, {id: 2, name: "4-8 hour response"}, {
            id: 3, name: "2-4 hour response"
        }, {id: 4, name: "Emergency response"},],

        price: null,
        note: null,
        noteRules: [
            v => (!v || v.length <= 1000) || 'Note must be less than 100 characters',
        ],
        bookingDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10),
        dialogCalendar: false,
        startTimeDialog: false,
        endTimeDialog: false,
        startTime: null,
        endTime: null,

        isActive: true,
        errors: [],
        buttonLoader: 'buttonLoading',
        buttonLoading: false,
        dialogAddItem: false,

        title: "Services",

        options: null,
        desserts: [],
        loading: true,
        headers: [
            {text: 'Customer', value: 'userID', align: 'center'},
            {text: 'Type', value: 'typeID', align: 'center'},
            {text: 'Category', value: 'categoryID', align: 'center'},
            {text: 'Booking Date', value: 'bookingDate', align: 'center'},
            {text: 'Status', value: 'isActive', align: 'center'},
            {text: 'Actions', value: 'actions', sortable: false, align: 'center'},
        ],
        isFormTitleEdit: false,


    }),

    created() {
        this.getUsersFromApi();
        this.getJobCategoriesFromApi();
        this.getServicesFromApi();
    },


    computed: {
        formTitle() {
            return this.isFormTitleEdit ? 'Edit service' : 'Add new service'
        },
    },

    watch: {


        dialogAddItem() {
            if (!this.dialogAddItem) {
                this.isFormTitleEdit = false;
                this.clear();
            }

        },


    },

    methods: {
        getServicesFromApi() {
            this.loading = true;
            networks.fetchFromWeb(urlServices)
                .then(response => {

                    this.desserts = response.data;
                    this.loading = false;
                })
                .catch(error => {
                    alert(error);
                });


        },

        getUsersFromApi() {
            this.loading = true;
          networks.fetchFromWeb(urlUsersBasedRole)
                .then(response => {
                    this.users = response.data;
                    this.loading = false;
                })
                .catch(error => {
                    alert(error);
                });
        },

        getJobCategoriesFromApi() {
            this.loading = true;
            networks.fetchFromWeb(urlJobCategories)
                .then(response => {
                    this.jobCategories = response.data;
                    this.categories = response.data.filter(jobCategory => jobCategory.isTypeCategory === true);
                    this.types = response.data.filter(jobCategory => jobCategory.isTypeCategory === false);
                    this.loading = false;
                })
                .catch(error => {
                    alert(error);
                });
        },

        getJobCategoryName(id) {
            let parent = this.jobCategories.filter(c => c.id == id);
            return parent[0].name;
        },

        getCustomerName(item) {
            let parent = this.users.filter(c => c.id == item.userID);
            return parent[0]?.name;
        },
        validate() {

            if (this.$refs.form.validate()) {
                this.buttonLoading = true;
                this.errors = [];
                this.saveItem();
            }
        },

        saveItem() {
            let data = {
                userID: this.user.id,
                typeID: this.type.id,
                categoryID: this.category.id,
                priority: this.priority.id,
                price: this.price,
                bookingDate: this.bookingDate,
                startTime: this.startTime,
                endTime: this.endTime,
                note: this.note,
                isActive: this.isActive,
            };

            if (this.isFormTitleEdit)
                networks.fetchFromWeb(urlUpdateService + this.id, 2, data)
                    .then(response => {

                        if (response.status == 200) {
                            this.clear();
                            this.dialogAddItem = false;
                            alert("the data has been edited successfully");
                            this.getServicesFromApi();
                        }


                    })
                    .catch(error => {
                        this.errors = error.response.data.errors || error.response.data.error;
                        this.buttonLoading = false;
                    });
            else {
                networks.fetchFromWeb(urlCreateService, 1, data)
                    .then(response => {

                        if (response.status == 200) {
                            this.clear();
                            this.dialogAddItem = false;
                            alert("the data has been added successfully");
                            this.getServicesFromApi();
                        }


                    })
                    .catch(error => {
                        this.errors = error.response.data.errors || error.response.data.error;
                        this.buttonLoading = false;

                    });
            }
        },

        clear() {
            if (this.$refs.form)
                this.$refs.form.resetValidation();

            this.user = null;
            this.type = null;
            this.category = null;
            this.priority = null;
            this.bookingDate = null;
            this.startTime = null;
            this.endTime = null;
            this.note = null;
            this.price = null;
            this.id = 0;
            this.isActive = true;
            this.valid = true;
            this.errors = [];
            this.buttonLoading = false;
        },


        editItem(item) {
            this.clear();
            this.isFormTitleEdit = true;
            this.id = item.id;
            this.user = {id: item.userID};
            this.type = {id: item.typeID};
            this.category = {id: item.categoryID};
            this.priority = {id: item.priority};
            this.bookingDate = item.bookingDate;
            this.startTime = item.startTime;
            this.endTime = item.endTime;
            this.note = item.note;
            this.price = item.price;
            this.isActive = item.isActive;
            this.dialogAddItem = true;
        },

        deleteItem(item) {
            const index = this.desserts.indexOf(item);
            confirm('do you want to delete this ?') && this.desserts.splice(index, 1)
        },


    },
}
</script>



